import Style from './FJFeatureWidthIcon.module.less';

import { Component } from 'react';
import classnames from 'classnames';
import FJUtil from '../../../src/util/FJUtil';
import FJSvg from '../../FJSvg/FJSvg';
import classname from 'classnames';
import FJSrcUtil from '../../../src/util/FJUtil';

export default class FJFeatureWidthIcon extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
  }

  __drawHTag() {
    const { hTag = 'h2', data = {} } = this.props;
    switch (hTag) {
      case 'h1':
        return <h1 className={Style.title}>{data.title}</h1>;
      case 'h2':
        return <h2 className={Style.title}>{data.title}</h2>;
      case 'h3':
        return <h3 className={Style.title}>{data.title}</h3>;
      case 'h4':
        return <h4 className={Style.title}>{data.title}</h4>;
      case 'h5':
        return <h5 className={Style.title}>{data.title}</h5>;
      case 'h6':
        return <h6 className={Style.title}>{data.title}</h6>;
      default:
        return <h2 className={Style.title}>{data.title}</h2>;
    }
  }

  __drawDesc() {
    const { data = {} } = this.props;
    if (data.desc) {
      if (Array.isArray(data.desc)) {
        return (
          <ul className={Style.featureList}>
            {data.desc.map((item, index) => {
              return (
                <li key={index}>
                  {item.icon ? (
                    item.icon.src ? (
                      <FJSvg src={item.icon} className={Style.featureItemIcon} />
                    ) : (
                      <img
                        className={Style.featureItemIcon}
                        src={item.icon}
                        width={20}
                        height={20}
                        alt={'feature' + index}
                        loading='lazy'
                      />
                    )
                  ) : (
                    ''
                  )}
                  <p dangerouslySetInnerHTML={{ __html: item.desc }} />
                </li>
              );
            })}
          </ul>
        );
      } else {
        return <p className={Style.descBox} dangerouslySetInnerHTML={{ __html: data.desc }} />;
      }
    }
  }

  __drawButton() {
    const { data = {}, customClick, target = '_blank', ealog = null } = this.props;
    let URL = data.target
      ? data.target !== '#' && data.target.indexOf('http') === -1
        ? FJUtil.getCurrentUrl() + data.target
        : data.target || '#'
      : '#';

    function jumpToEdit(event) {
      if (event.preventDefault) {
        event.preventDefault();
      } else {
        event.returnValue = false;
      }
      // _self
      if (target === '_blank') {
        window.open(URL);
      } else {
        window.location.href = URL;
      }
    }

    function buttonClick(e) {
      if (ealog) {
        FJUtil.ealog(ealog.eventType, ealog.eventData);
      }

      // 有自定义按钮点击事件就执行自定义，否则执行默认跳转
      if (customClick) {
        customClick();
        return;
      }

      jumpToEdit(e);
    }

    if (data.btnText) {
      if (data.btnType === 'a') {
        return (
          <a
            className={classnames(Style.btn)}
            href={URL}
            rel='nofollow noreferrer'
            onClick={e => {
              buttonClick(e);
            }}
          >
            {data.btnText}
          </a>
        );
      } else {
        return (
          <div
            className={classnames(Style.btn, 'upload-button')}
            onClick={e => {
              buttonClick(e);
            }}
          >
            {data.btnText}
          </div>
        );
      }
    }
  }

  blockMoveToCenter = () => {
    const { leftAnimationKeyframe, rightAnimationKeyframe } = this.props;
    const AnimationHandler = new FJSrcUtil.AnimationHandler();
    AnimationHandler.transformAnimation(
      this.leftDom,
      leftAnimationKeyframe,
      {
        duration: 1000,
        delay: 0,
        easing: 'linear',
        fill: 'forwards',
      },
      true,
    );
    AnimationHandler.transformAnimation(
      this.rightDom,
      rightAnimationKeyframe,
      {
        duration: 1000,
        delay: 0,
        easing: 'linear',
        fill: 'forwards',
      },
      true,
    );
  };

  unobserve = () => {
    const AnimationHandler = new FJSrcUtil.AnimationHandler();
    AnimationHandler.unobserve(this.leftDom);
    AnimationHandler.unobserve(this.rightDom);
  };

  componentDidMount(prevProps, prevState, snapshot) {
    const { useAnimation = false } = this.props;

    if (!useAnimation) {
      return;
    }
    this.blockMoveToCenter();
  }

  componentWillUnmount() {
    const { useAnimation = false } = this.props;
    if (!useAnimation) {
      return;
    }
    this.unobserve();
  }

  render() {
    const { data = {}, isReverse, className = '', imgClassName = '', domPercent, useAnimation = false } = this.props;
    let leftDomPercent = {};
    let rightDomPercent = {};

    if (domPercent) {
      if (domPercent.left) {
        leftDomPercent = { width: domPercent.left };
      }
      if (domPercent.right) {
        rightDomPercent = { width: domPercent.right };
      }
    }
    return (
      <div
        className={classnames(Style.featureBox, isReverse ? Style.boxReverse : '', className)}
        ref={e => (this.featureBox = e)}
      >
        <div
          className={classname(
            Style.featureInfoBox,
            isReverse ? Style.featureInfoBoxReverse : '',
            useAnimation ? Style.useAnimationClass : '',
          )}
          style={leftDomPercent}
          ref={e => (this.leftDom = e)}
        >
          {this.__drawHTag()}
          {this.__drawDesc()}
          {this.__drawButton()}
        </div>
        <div
          className={classname(Style.featureImgBox, imgClassName, useAnimation ? Style.useAnimationClass : '')}
          style={rightDomPercent}
          ref={e => (this.rightDom = e)}
        >
          {data.imgUrl && <img src={data.imgUrl} width={853} height={515} alt={data.title || ''} loading='lazy' />}
        </div>
      </div>
    );
  }
}
